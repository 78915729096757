let options2 = {
  root: null,
  rootMargin: '-130px 0px 0px',
  threshold: 0.01,
}

let callback2 = (entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      document.querySelector('#rinrose_home-splash_activate').classList.add('splash-active');
      return;
    }else {
      document.querySelector('#rinrose_home-splash_activate').classList.remove('splash-active');
      return;
    }
  });
};

const observer2 = new IntersectionObserver(callback2, options2);

function home_splash_animation() {
  document.body.classList.remove('no-scroll-true');
  document.querySelector('#rinrose_home-splash_mask-logo').classList.add('splash-exit-triggered');
  document.querySelector('#rinrose_home-splash_mask').classList.add('splash-exit-triggered');
  document.querySelector('#rinrose_home-splash_video').classList.add('splash-exit-triggered');
  document.querySelector('#rinrose_home-splash_activate').classList.add('splash-exit');
  setTimeout(function() {
    document.querySelector('#rinrose_home-splash_activate').classList.remove('splash-active', 'splash-exit');
  }, 250);
}

export default {
  init() {
    // JavaScript to be fired on the home page
    if(!window.pageYOffset) {
      document.body.classList.add('no-scroll-true');
      document.querySelector('#rinrose_home-splash_activate').classList.add('splash-active');
    }else {
      document.querySelector('#rinrose_home-splash_activate').classList.remove('splash-active');
    }
    window.onscroll = function() {
      if(window.pageYOffset) {
        document.body.classList.remove('no-scroll-true');
        document.querySelector('#rinrose_home-splash_activate').classList.remove('splash-active');
      }
    }
    setTimeout(home_splash_animation, 5000);

    observer2.observe(document.querySelector('#rinrose_home-splash'));
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};